* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.layout-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.top-icon img {
  max-width: 200px;
}

.profile-section,
.profile-image img {
  width: 100%;
  border-radius: 15px;
}

.dark-bg {
  background-color: #1a1a1a;
}

.purple-bg {
  background-color: #1226e7;
}

.white-bg {
  background-color: #fff;
}

.black-icon i {
  color: #1a1a1a;
}

.icon-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px 8px;
}

.icon-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100px;
  color: #fff;
  padding: 25px;
  border-radius: 15px;
}

.one-row-item {
  flex-grow: 1;
  border-radius: 25px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

.big-icon {
  aspect-ratio: 1/1;
}

.icon-item i {
  font-size: 30px;
}

.icon-item.schedule {
  border: none;
}

.icon-item.schedule:hover {
  cursor: pointer;
}

.icon-item.schedule i {
  font-size: 42px;
}

.qr-code-container {
  background-color: #fff;
  text-align: center;
  padding: 12px;
  border-radius: 15px;
  width: 100%;
}

img.qr-code {
  width: 70%;
}

.footer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-icon {
  width: 45px;
}

@media screen and (min-width: 700px) {
  .layout-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 100px;
  }
}

@media screen and (min-width: 440px) {
  .layout-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 2.5rem;
  }

  .one-row-item {
    aspect-ratio: 1/1;
    border-radius: 15px;
  }

  img.qr-code {
    width: 60%;
  }
}

@media screen and (max-width: 439px) {
  .layout-container {
    padding: 10px;
  }
  .one-row-item {
    min-width: 300px;
    flex-grow: 1;
    border-radius: 25px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
  }
}
